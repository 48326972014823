import React from "react";

import Layout from "../../../components/layout";
import SmallBanner from "../../../components/smallBanner";

const Quality = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Qualitat"
      lang={lang}
      translationPL="/oferta/jakosc/"
      translationEN="/en/our-services/quality/"
    >
      <SmallBanner
        title="Qualität"
        aditionalBreadcrump="Angebot"
        aditionalBreadcrumpLink="/de/angebot/"
      />
      <section className="text-section quality-info text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
              PalettenWerk Qualitätssicherungssystem
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-4">
              <img
                className="quality-info__left-image"
                src={require("../../../assets/img/quality2.jpg")}
                alt="Jakość"
              />
            </div>
            <div className="col-12 col-lg-9 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                PalettenWerk verfügt über ein 6-stufiges Qualitätskontrollsystem, das die Wiederholbarkeit, Haltbarkeit und langfristige Nutzbarkeit der Paletten garantiert. Das System umfasst die Kontrolle der Lieferung von Palettenkomponenten, die Kontrolle des Produktionsprozesses und der Fertigprodukte. Alle Kontrollprozesse sind in das Qualitätsmanagementsystem integriert.
                </p>
                <p className="mb-60">
                Die Qualitätskontrolle beginnt bereits bei den Lieferanten von Elementen für die Herstellung von Paletten und dient dazu, mögliche Unregelmäßigkeiten auszuschließen, die bei der Vorbereitung des Rohmaterials für die Produktion auftreten können. Palettenkomponenten unterliegen auch der Eingangskontrolle im Werk und an jeder Arbeitsstation, die sie durchlaufen.
                </p>
                <img
                  src={require("../../../assets/img/quality1.jpg")}
                  alt="Jakość"
                />
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                    Kontrolle des Produktionsprozesses:
                    </h4>
                    <div className="item__content ">
                      <p className="content__item">
                      Kontrolle der Palettenkomponenten 
                        <br />
                        bei Lieferanten 
                      </p>
                      <p className="content__item">
                      Kontrolle beim Eingang 
                        <br />
                        der Lieferung im Werk 
                      </p>
                      <p className="content__item">
                        Interne Kontrolle 
                        <br />
                        in der Produktionsphase
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__qulity-check">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">
                    Kontrolle der Fertigprodukte:
                    </h4>
                    <div className="item__content">
                      <p className="content__item">
                      Interne Kontrolle 
                        <br />
                        von Fertigprodukten
                      </p>
                      <p className="content__item">
                      Externe Kontrolle 
                        <br />
                        von Fertigprodukten
                      </p>
                      <p className="content__item">
                      Externe Kontrolle 
                        <br />
                        von Europaletten 
                        <br />nach der Norm UIC 435-2
                      </p>
                    </div>
                    <p className="item__bottom-info bold">
                    ISO 9001:2015 Zertifikat
                    </p>
                  </div>
                </div>
                <div className="content__qulity-check content__qulity-check--ready">
                  <div className="qulity-check__item">
                    <h4 className="item__title bold mb-50">Fertige Erzeugnisse:</h4>
                    <div className="item__content">
                      <p className="content__item">EPAL-Kennzeichnung</p>
                      <img
                        className="content__item"
                        src={require("../../../assets/img/palete-quality.png")}
                        alt="Paleta"
                      />
                      <p className="content__item">IPPC Zertifikat</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Quality;
